import {getFFMPEG, fetchFile} from '../getFFMPEG.js';
import React, {useState, useMemo, useEffect, useRef, Suspense} from 'react';

import axios from 'axios';
import styled, {css} from 'styled-components';
import {motion, AnimatePresence} from 'framer-motion';
import OpenAI from 'openai';
import moment from 'moment';

// Chakra
// import {Alert} from '@chakra-ui/react';
import MultiTypeInput from '../MultiTypeInput.js';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
import {Button, ButtonGroup, Input, Textarea} from '@chakra-ui/react';
import {Progress} from '@chakra-ui/react';
import {Box as ChakraBox} from '@chakra-ui/react';
import {Select as ChakraSelect} from '@chakra-ui/react';
import {Tag, TagLabel, TagCloseButton} from '@chakra-ui/react';

import {Reorder} from 'framer-motion';

// Download icon from react-icons
// import {MdFileDownload} from 'react-icons/md';

// Check icon
// import {FaCheck} from 'react-icons/fa';

import srtParser2 from 'srt-parser-2';

import AudioPlayer from 'react-h5-audio-player';
import './custom-audio-player.scss';

import WordSplitter from './WordSplitter.js';

// Re-ordable list of tags
const ChipList = ({tags, setTags, inactiveTags, ...props}) => {
    return (
        <div>
            <Reorder.Group
                {...props}
                // x and y
                axis="x"
                values={tags}
                onReorder={newTags => {
                    setTags(newTags);
                }}
                style={{
                    // Unset ul styles
                    listStyleType: 'none',
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    overflowX: 'auto'
                }}
            >
                {tags.map((tag, index) => (
                    <Reorder.Item key={tag} value={tag}>
                        <Tag
                            size="lg"
                            borderRadius="full"
                            variant="solid"
                            colorScheme="purple"
                            m={1}
                            className="handle"
                            style={{
                                cursor: 'grab',
                                userSelect: 'none',
                                whiteSpace: 'nowrap'
                            }}
                            onDoubleClick={() => setTags(tags.filter(t => t !== tag))}
                        >
                            <TagLabel>{(TEXT_TRANSFORMATIONS.find(t => t[0] === tag) || [tag, tag])[1]}</TagLabel>
                            <TagCloseButton onClick={() => setTags(tags.filter(t => t !== tag))} />
                        </Tag>
                    </Reorder.Item>
                ))}
            </Reorder.Group>
            {inactiveTags.map(tag => (
                <Tag
                    key={tag}
                    size="lg"
                    borderRadius="full"
                    variant="outline"
                    colorScheme="purple"
                    m={1}
                    onClick={() => setTags([...tags, tag])}
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <TagLabel>{(TEXT_TRANSFORMATIONS.find(t => t[0] === tag) || [tag, tag])[1]}</TagLabel>
                </Tag>
            ))}
        </div>
    );
};

// Multi select box
const Select = ({options, value, onChange, ...props}) => {
    // Should show a marking on the selected options
    return (
        <>
            <ChakraSelect
                onChange={e => {
                    if (value && value.includes(e.target.value)) {
                        onChange(value.filter(v => v !== e.target.value));
                    } else {
                        onChange([...(value || []), e.target.value]);
                    }

                    e.target.value = 'none';
                }}
                {...props}
            >
                {[['none', 'Select transformations'], ...options].map(([optionValue, optionLabel]) => (
                    <option key={optionValue} value={optionValue}>
                        {optionLabel}
                    </option>
                ))}
            </ChakraSelect>
        </>
    );
};

// Smooth scroll, and prevent scroll jumping
const smoothScrollToBottom = element => {
    requestAnimationFrame(() => {
        element.scrollTop = element.scrollHeight;
    });
};

const TextareaDebounced = React.memo(({value, onChange, ...props}) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        if (value === localValue) {
            return;
        }

        const timeout = setTimeout(() => {
            onChange({target: {value: localValue}});
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [localValue, onChange]);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <motion.div
            // Animate height auto when showing and hiding
            initial={{height: 0}}
            animate={{height: 'auto'}}
            exit={{height: 0}}
            transition={{duration: 0.3}}
            style={{
                display: 'flex',
                overflow: 'hidden'
            }}
        >
            <Textarea value={localValue} onChange={e => setLocalValue(e.target.value)} {...props} />
        </motion.div>
    );
});

const ProgressLabel = styled.div`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;

    ${props =>
        props.loading &&
        css`
            animation: blinker 1s linear infinite;
        `}

    @keyframes blinker {
        50% {
            opacity: 0.6;
        }
    }
`;

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        if (key.endsWith('_')) {
            return initialValue;
        }
        const storedValue = window.localStorage.getItem(key);
        if (storedValue) {
            try {
                return JSON.parse(storedValue);
            } catch (error) {
                return storedValue === 'undefined' ? initialValue : storedValue;
            }
        }
        return initialValue;
    });

    useEffect(() => {
        if (key.endsWith('_')) {
            return;
        }
        const storedValue = window.localStorage.getItem(key);
        if (storedValue) {
            try {
                setValue(JSON.parse(storedValue));
            } catch (error) {
                setValue(storedValue === 'undefined' ? initialValue : storedValue);
            }
        }
    }, [key]);

    useEffect(() => {
        if (key.endsWith('_')) {
            return;
        }
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

var parser = new srtParser2();

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${secs.toString().padStart(2, '0')},${ms.toString().padStart(3, '0')}`;
}

const CustomBox = styled(ChakraBox)`
    color: black;
    /* Make scrollbars light */
    ::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    // All placeholders
    ::-webkit-input-placeholder {
        color: #888;
    }
`;

const processWordLevelData = (data, longPauseMultiplier) => {
    let srtContent = '';
    let srtIndex = 1;
    let currentSentence = [];
    let startTime = null;

    const formatTime = seconds => {
        return moment.utc(seconds * 1000).format('HH:mm:ss,SSS');
    };

    const gaps = data.words.slice(1).map((word, i) => word.start - data.words[i].end);
    const averageGap = gaps.reduce((sum, gap) => sum + gap, 0) / gaps.length;

    data.words.forEach((word, index) => {
        if (!startTime) startTime = word.start;
        currentSentence.push(word.word);

        const isLastWord = index === data.words.length - 1;
        const nextWord = data.words[index + 1];
        const timeTillNextWord = nextWord ? nextWord.start - word.end : 0;

        if (word.word.match(/[.!?]$/) || timeTillNextWord > averageGap * longPauseMultiplier || isLastWord) {
            const endTime = nextWord ? nextWord.start : word.end;
            const sentenceText = currentSentence.join(' ').trim();

            srtContent += `${srtIndex}\n`;
            srtContent += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
            srtContent += `${sentenceText}\n\n`;

            srtIndex++;
            currentSentence = [];
            startTime = endTime;
        }
    });

    return srtContent.trim();
};

const Box = styled(ChakraBox)`
    color: black;
    /* Make scrollbars light */
    ::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const AccessTokenInput = ({value, onChange}) => {
    const [showKey, setShowKey] = useState(value.length > 0 ? false : true);

    const hiddenValue = useMemo(() => {
        return showKey ? value : `OpenAI API Access Token: ${value.slice(0, 5)} ${'*'.repeat(4)} ${value.slice(-5)}`;
    }, [showKey, value]);

    return (
        <div
            onClick={() => setShowKey(true)}
            style={{
                cursor: 'pointer',
                userSelect: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}
        >
            <Input
                variant={showKey ? 'outline' : 'filled'}
                style={!showKey ? {pointerEvents: 'none'} : {}}
                value={hiddenValue}
                onChange={onChange}
                placeholder="OpenAI API Key"
                onBlur={() => setShowKey(false)}
            />
        </div>
    );
};

const closest = (el, selector) => {
    while (el && el.nodeType === 1) {
        if (el.matches(selector)) {
            return el;
        }
        el = el.parentNode;
    }
    return null;
};

// Get ffmpeg instance preloaded
getFFMPEG();

let mp3Cache = window.mp3Cache || {};
window.mp3Cache = mp3Cache;

const convertToMp3 = async (audioFile, audioLength, folderHandle, name, logger) => {
    if (mp3Cache[name]) {
        return await mp3Cache[name];
    }

    mp3Cache[name] = new Promise(async (resolve, reject) => {
        if (folderHandle) {
            folderHandle = await folderHandle.getDirectoryHandle('mp3', {create: true});

            const files = await folderHandle.values();
            for await (const file of files) {
                if (file.name === name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3') {
                    const data = await file.getFile();
                    const audioFileMp3 = new File([data], file.name, {
                        type: 'audio/mp3'
                    });
                }
            }

            for await (const file of files) {
                await file.removeEntry();
            }

            mp3Cache = {};
        }

        mp3Cache[name] = new Promise(async (resolve, reject) => {
            const ffmpeg = await getFFMPEG(logger);

            await ffmpeg.writeFile('ogfile', await fetchFile(audioFile));

            console.log('Converting to mp3... ' + audioLength + 's');

            await ffmpeg.exec(['-i', 'ogfile', '-preset', 'ultrafast', '-codec:a', 'libmp3lame', '-b:a', '160k', name]);

            const data = await ffmpeg.readFile(name);

            const audioFileMp3 = new File([data], name.replace(/\.(mp3|wav|ogg)$/, '.mp3'), {
                type: 'audio/mp3'
            });

            if (folderHandle) {
                await folderHandle.getFileHandle(name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3', {
                    create: true
                });
                const writable = await folderHandle.getFileHandle(name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3', {
                    create: true
                });
                const writableStream = await writable.createWritable();
                await writableStream.write(data);
                await writableStream.close();
            }

            resolve(audioFileMp3);
        });

        resolve(await mp3Cache[name]);
    });

    return await mp3Cache[name];
};

const triggerDownload = (content, type, filename) => {
    const blob = new Blob([content], {type: `text/${type}`});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.${type}`;
    a.click();
    URL.revokeObjectURL(url);
};

const useHash = (value, valueProp) => {
    // Value could be file or string
    const [hash, setHash] = useState('');

    useEffect(() => {
        if (valueProp) {
            setHash(valueProp);
            return;
        }

        if (value instanceof File) {
            const reader = new FileReader();
            reader.onload = function () {
                const arrayBuffer = this.result;
                crypto.subtle.digest('SHA-256', arrayBuffer).then(hash => {
                    const hashArray = Array.from(new Uint8Array(hash));
                    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                    setHash(hashHex);
                });
            };
            reader.readAsArrayBuffer(value);
        } else if (typeof value === 'string') {
            crypto.subtle.digest('SHA-256', new TextEncoder().encode(value)).then(hash => {
                const hashArray = Array.from(new Uint8Array(hash));
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                setHash(hashHex);
            });
        }
    }, [value]);

    return hash;
};

const roundStartAndEndTimes = wordsText => {
    // Rounds to 2 decimal places
    return wordsText.replace(/(\d+\.\d{2})\d+/g, '$1');
};

const prepareLyrics = lyrics => {
    return (
        lyrics
            // Remove Chorus written in case insensitive along with the entire line
            .replace(/^.*chorus.*$/gim, '')
            // Remove lines with specific patterns (vers.*, chorus.*, intro.*, outro.*, etc.)
            .replace(
                /^.*(vers.*?|chorus.*?|intro.*?|outro.*?|bridge.*?|hook.*?|pre-chorus.*?|pre-verse.*?|post-chorus.*?|post-verse.*?|interlude.*?).*$/gim,
                ''
            )
            // Remove like "Refräng" or "Vers 1" and its whole line, the sweddish version of chorus and verse
            .replace(
                /^.*(refräng|vers|intro|outro|utro|brygga|hook|pre-chorus|pre-verse|post-chorus|post-verse|interlude|mellanspel|spoken|bridge|chorus|verse).*$/gim,
                ''
            )
            // Insert line breaks after , and . and ? and ! and :
            .replace(/[,\.!?] ?/g, '\n')
            // Replace , and . with line breaks
            // .replace(/[,\.] ?/g, '\n')
            // Remove empty lines
            .replace(/^\s*[\r\n]/gm, '')
    );
};

// Show a toggleable text editor
const colorize = (text, color) => {
    return `<span style="color: ${color}">${text}</span>`;
};
const PromptEditor = ({promptTemplate, setPromptTemplate, wordsData, lyrics}) => {
    const [showPrompt, setShowPrompt] = useState(false);
    const [preview, setPreview] = useState(true);

    const withLyrics = useMemo(() => {
        const wordsText = wordsData?.words?.map(word => `${word.word}|${word.start}|${word.end}`).join(' ') || '';

        return (
            showPrompt &&
            promptTemplate
                .replace(/\[words\]/g, colorize(roundStartAndEndTimes(wordsText).trim(), 'blue'))
                .replace(/\[lyrics\]/g, colorize(prepareLyrics(lyrics || '').trim(), 'green'))
        );
    }, [showPrompt, promptTemplate, wordsData, lyrics]);

    return (
        <CustomBox my={4}>
            <AnimatePresence>
                {showPrompt &&
                    (preview ? (
                        <TextareaDebounced
                            as={motion.div}
                            key="prompt"
                            style={{
                                background: 'white',
                                cursor: 'pointer',
                                height: '200px',
                                overflow: 'auto',
                                // Wrap text
                                whiteSpace: 'pre-wrap'
                            }}
                            onClick={e => {
                                setPreview(false);
                            }}
                            // Dangerously set inner HTML
                            dangerouslySetInnerHTML={{__html: withLyrics}}
                        />
                    ) : (
                        <TextareaDebounced
                            key="prompt"
                            value={promptTemplate}
                            onChange={e => {
                                setPromptTemplate(e.target.value);
                            }}
                            style={{
                                background: 'white',
                                height: '200px'
                            }}
                            onBlur={e => {
                                setPreview(true);
                            }}
                        />
                    ))}

                <Button
                    key="togglePrompt"
                    as={motion.button}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    onClick={() => {
                        setShowPrompt(!showPrompt);
                    }}
                    mt={2}
                    colorScheme={showPrompt ? 'purple' : 'gray'}
                >
                    {showPrompt ? 'Hide Prompt' : 'Edit Prompt (advanced)'}
                </Button>

                {/* Reset prompt */}
                {showPrompt && (
                    <Button
                        key="resetPrompt"
                        as={motion.button}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        onClick={() => {
                            if (window.confirm('Are you sure you want to reset the prompt?')) {
                                setPromptTemplate(defaultPromptTemplate);
                            }
                        }}
                        mt={2}
                        colorScheme="gray"
                    >
                        Reset Prompt
                    </Button>
                )}
            </AnimatePresence>
        </CustomBox>
    );
};

// Updated defaultPromptTemplate without mentions of new lines
const defaultPromptTemplate = `### Goal
Correct the timecoded text by matching it to the provided lyrics, making necessary word adjustments (merge, split, replace).

### Timecoded Text Format
- **Format:**  
  \`Word|StartTime|EndTime\`
- **Example:**  
  \`One|0.0|1.2 two|1.3|2.4 three|2.5|3.1\`

### Word Adjustment Rules
- **Merge:** Combine adjacent words, adjusting their times.
  - **Example:**  
    \`One|0.0|1.2 two|1.3|2.4 -> Onetwo|0.0|2.4\`
- **Split:** Break a word into multiple words, estimating new times.
  - **Example:**  
    \`Onetwothree|0.0|3.0 -> One|0.0|1.0 two|1.0|2.0 three|2.0|3.0\`

### Task Steps
1. **Parse Timecoded Text:** Break down the timecoded text into words with their timings.
2. **Process Lyrics:** Convert lyrics into a list of words.
3. **Correct Timecoded Text:**
   - Match words from lyrics to timecoded text.
   - Split or merge words to align with lyrics.
   - Try and replace words with the correct ones from lyrics.
4. **Generate Output:** Produce the corrected timecoded text in the specified format.

### Output Requirements
- **Output:** Only the corrected timecoded text without code blocks or additional content.
- **Each word:** Should have a start and end time.

### Example

**Input:**

- **Timecoded Text:**  
  \`One|0.0|1.2 too|1.3|2.4 tree|2.5|3.1 four|3.2|4.0\`
- **Lyrics:**  
  \`One two Three fourfive\`

**Expected Output:**
\`One|0.0|1.2 two|1.3|2.4 Three|2.5|3.1 fourfive|3.2|4.0\`

**Timecoded Words with Start and End Times:**
[words]

**Lyrics Text:**
[lyrics]`;

const TEXT_TRANSFORMATIONS = [
    ['break-on-each-word', 'Break on each word'],
    ['split-every-5-words', 'Split every 5 words'],
    ['remove-single-word-line-breaks', 'Remove single word line breaks'],
    ['break-by-space-between-words', 'Break by space between words'],
    ['break-by-capital-letters', 'Break by capital letters'],
    ['break-by-mid-sentence', 'Break by mid-sentence'],
    ['remove-line-breaks', 'Remove all line breaks']
];

const SrtGeneratorWordsBatch = React.memo(
    ({open, closeModal, audioSrc, audioLength, audioFileNameProp, data, folderHandle}) => {
        const [showSrt, setShowSrt] = useLocalStorage('showSrt', false);
        const [openAiToken, setOpenAiToken] = useLocalStorage('openAiToken', '');
        const [audioFileName, setAudioFileName] = useState('');
        const [audioFile, setAudioFile] = useState(null);
        const [audioUrl, setAudioUrl] = useState(audioSrc || '');
        const audioFileHash = useHash(audioFile);
        const [lyrics, setLyrics] = useLocalStorage('lyrics_' + audioFileHash, '');
        const [hideLyrics, setHideLyrics] = useLocalStorage('hideLyrics', false);
        const [srt, setSrt] = useLocalStorage('srt_' + audioFileHash, '');
        const [untransformedSrt, setUntransformedSrt] = useLocalStorage('untransformedSrt_' + audioFileHash, '');
        const [srtData, setSrtData] = useLocalStorage('srtData_' + audioFileHash, '');
        const [wordsData, setWordsData] = useLocalStorage('wordsData_' + audioFileHash, '');
        const [enhancedSrt, setEnhancedSrt] = useLocalStorage('enhancedSrt_' + audioFileHash, '');
        const [loading, setLoading] = useState(false);
        const [statusMessage, setStatusMessage] = useState('');
        const [logMessages, setLogMessages] = useState([]);
        const [progress, setProgress] = useState(0);
        const [showLyrics, setShowLyrics] = useLocalStorage('showLyrics', false);
        const [streamingResponse, setStreamingResponse] = useState('');

        const wordSplitterRef = useRef(null);
        const srtRef = useRef(null);

        const audioPlayerRef = useRef(null);
        const audioRef = useRef(null);
        const [model, setModel] = useLocalStorage('model', 'gpt-4o');

        const [promptTemplate, setPromptTemplate] = useLocalStorage('promptTemplate', defaultPromptTemplate);

        const [textTransformations, setTextTransformations] = useLocalStorage('textTransformations2', [
            'break-by-capital-letters',
            'break-by-mid-sentence'
        ]);

        useEffect(() => {
            // If data is set and audioFileHashProp, get the file from the folderHandle mp3 folder (if it exists) and then set the rest of the data as well
            console.log('data', data);
            console.log('audioFileNameProp', audioFileNameProp);
            if (data && audioFileNameProp) {
                const fetchData = async () => {
                    const folderHandleSub = await folderHandle.getDirectoryHandle('mp3', {
                        create: true
                    });

                    const files = await folderHandleSub.values();
                    for await (const file of files) {
                        if (file.name === audioFileNameProp) {
                            const fileData = await file.getFile();
                            const audioFileMp3 = new File([fileData], file.name, {
                                type: 'audio/mp3'
                            });

                            setAudioFile(audioFileMp3);
                            setAudioFileName(file.name);
                            setAudioUrl('');
                        }
                    }
                };

                fetchData();
            }
        }, [data, audioFileNameProp]);

        // On hash change if data is set
        useEffect(() => {
            if (data) {
                setWordsData(data.wordsData);
                setLyrics(data.lyrics);
                setSrt(data.srt);
                setSrtData(data.srtData);
                setEnhancedSrt(data.enhancedSrt);

                setStatusMessage('Data loaded from hash.');
                setProgress(100);
            }
        }, [audioFileHash]);

        const generateWordsJson = async () => {
            setLoading(true);
            setStatusMessage('Generating words JSON...');
            try {
                const formData = new FormData();
                formData.append('file', audioFile);
                formData.append('model', 'whisper-1');
                formData.append('response_format', 'verbose_json');
                formData.append('timestamp_granularities[]', 'word');

                // Language
                // formData.append('language', 'sv');

                const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
                    headers: {
                        Authorization: `Bearer ${openAiToken}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted * 0.8);
                    }
                });

                setStatusMessage('Words JSON generated successfully.');
                return response.data;
            } catch (error) {
                setStatusMessage(`Error: ${error.response?.data?.error || error.message}`);
                throw error;
            } finally {
                // setLoading(false);
            }
        };

        const enhanceLyrics = async (wordsData, lyrics) => {
            // If lyrics are empty, return stream that just returns the words data formatted
            if (!showLyrics || !lyrics) {
                const wordsText = wordsData.words.map(word => `${word.word}|${word.start}|${word.end}`).join(' ');

                // .choices[0]?.delta?.content || '';
                return {
                    [Symbol.asyncIterator]: async function* () {
                        yield {
                            choices: [
                                {
                                    delta: {
                                        content: roundStartAndEndTimes(wordsText)
                                    }
                                }
                            ]
                        };
                    }
                };
            }

            setLoading(true);
            setStatusMessage('Enhancing lyrics...');
            setProgress(0);
            const openai = new OpenAI({apiKey: openAiToken, dangerouslyAllowBrowser: true});
            try {
                // Split words into one per line before sending
                const wordsText = wordsData.words.map(word => `${word.word}|${word.start}|${word.end}`).join('\n');
                const wordPrompt = promptTemplate
                    .replace(/\[words\]/g, roundStartAndEndTimes(wordsText).trim())
                    .replace(/\[lyrics\]/g, prepareLyrics(lyrics).trim());

                const messages = [
                    ...(model.indexOf('o1') < 0
                        ? [
                              {
                                  role: 'system',
                                  content: 'You are a helpful assistant that corrects a transcribed text.'
                              }
                          ]
                        : []),
                    {
                        role: 'user',
                        content: wordPrompt
                    }
                ];
                const stream = await openai.chat.completions.create({
                    model: model,
                    messages: messages,
                    stream: true
                });

                return stream;
            } catch (error) {
                setStatusMessage(`Error enhancing lyrics: ${error.message}`);
                throw error;
            }
        };
        const srtTextToRealSrt = (content, extendLines = false) => {
            const lines = content.split('\n');
            let srtContent = '';
            let subtitleIndex = 1;
            let nextStartTime;

            lines.forEach((line, index) => {
                const words = line.split(' ').filter(word => word.length > 0);
                if (words.length === 0) {
                    return;
                }
                const firstWord = words[0].split('|');
                const lastWord = words[words.length - 1].split('|');

                const startTime = parseFloat(firstWord[1]);
                let endTime = parseFloat(lastWord[2]);

                // If extendLines is true and it's not the last line,
                // set endTime to the start time of the next line
                if (extendLines && index < lines.length - 1) {
                    const nextLine = lines[index + 1];
                    const nextWords = nextLine.split(' ').filter(word => word.length > 0);
                    if (nextWords.length > 0) {
                        const nextFirstWord = nextWords[0].split('|');
                        nextStartTime = parseFloat(nextFirstWord[1]);
                        endTime = nextStartTime;
                    }
                }

                const textContent = words.map(word => word.split('|')[0]).join(' ');

                srtContent += `${subtitleIndex}\n`;
                srtContent += `${formatTime(startTime)} --> ${formatTime(endTime)}\n`;
                srtContent += `${textContent}\n\n`;

                subtitleIndex++;
            });

            return srtContent;
        };

        useEffect(() => {
            if (srt) {
                setSrtData(srtTextToRealSrt(srt, true));
            }
        }, [srt]);

        const applyTextTransformations = specifiedSrt => {
            let newSrt = specifiedSrt || srt;

            // Function to process and split text segments
            const splitEvery5Words = segment => {
                const words = segment.split(' ');
                let result = [];
                for (let i = 0; i < words.length; i += 5) {
                    result.push(words.slice(i, i + 5).join(' '));
                }
                return result.join('\n');
            };

            if (textTransformations.length === 0) {
                setSrt(newSrt.replace(/\n/g, ' '));
                return;
            }

            // Apply transformations sequentially, with each one running on the result of the previous one
            textTransformations.forEach(transformation => {
                switch (transformation) {
                    case 'break-on-each-word':
                        newSrt = newSrt.replace(/ +/g, '\n');
                        break;

                    case 'split-every-5-words':
                        newSrt = newSrt.split('\n').map(splitEvery5Words).join('\n');
                        break;

                    case 'remove-single-word-line-breaks':
                        newSrt = newSrt.replace(/\n ([^\n ]+)/g, ' $1');
                        break;

                    case 'break-by-mid-sentence':
                        const conjunctions = [
                            'och',
                            ',',
                            'men',
                            'för',
                            'eller',
                            'så',
                            'fastän',
                            'trots att',
                            'även om',
                            'därför att',
                            'så att',
                            'eftersom',
                            'när',
                            'medan',
                            'innan',
                            'efter att',
                            'om',
                            'då',
                            'så länge som',
                            'genom att',
                            'på grund av att'
                        ];

                        newSrt = newSrt
                            .split('\n')
                            .map(segment => {
                                const words = segment.split(' ');
                                const result = [];
                                let currentSegment = [];

                                for (let i = 0; i < words.length - 1; i++) {
                                    const word = words[i].split('|')[0].toLowerCase();
                                    if (conjunctions.includes(word) && currentSegment.length > 2) {
                                        result.push(currentSegment.join(' '));
                                        result.push('\n');
                                        currentSegment = [];
                                    }

                                    currentSegment.push(words[i]);
                                }

                                currentSegment.push(words[words.length - 1]);
                                result.push(currentSegment.join(' '));
                                return result.join(' ').replace(/\s+\n/g, '\n');
                            })
                            .join('\n');
                        break;

                    case 'break-by-space-between-words':
                        newSrt = newSrt
                            .split('\n')
                            .map(segment => {
                                const words = segment.split(' ');
                                const result = [];
                                for (let i = 0; i < words.length - 1; i++) {
                                    const [word, , end] = words[i].split('|');
                                    const [nextWord, start] = words[i + 1].split('|');
                                    result.push(words[i]);
                                    if (parseFloat(end) < parseFloat(start)) {
                                        result.push('\n');
                                    }
                                }
                                result.push(words[words.length - 1]);
                                return result.join(' ').replace(/\s+\n/g, '\n');
                            })
                            .join('\n');
                        break;

                    case 'break-by-capital-letters':
                        newSrt = newSrt.replace(/ ([A-Z])/g, '\n$1');
                        break;

                    case 'remove-line-breaks':
                        newSrt = newSrt.replace(/\n/g, ' ');
                        break;

                    default:
                        break;
                }
            });

            // Post-processing step to remove single-word lines unless they are the start of a sentence or follow a specific transformation
            newSrt = newSrt
                .split('\n')
                .map(line => {
                    const words = line.trim().split(' ');
                    // Check if line has more than one word or is empty
                    if (words.length <= 1) {
                        return '';
                    }
                    return line;
                })
                .filter(line => line.trim() !== '') // Remove any empty lines
                .join('\n');

            setSrt(newSrt);
        };

        const handleGenerateSrt = async (withLyrics = false, wordsDataInput) => {
            if (!wordsDataInput) {
                wordsDataInput = wordsData;
            }

            if (!wordsDataInput || !wordsDataInput.words) {
                if (!audioFile) {
                    setStatusMessage('No audio file selected.');
                    return;
                }

                try {
                    setLoading(true);
                    let words = wordsDataInput;
                    if (!words || !words.words) {
                        words = await generateWordsJson();

                        setWordsData(words);
                        setUntransformedSrt(
                            words.words.map(word => `${word.word}|${word.start}|${word.end}`).join(' ')
                        );

                        handleGenerateSrt(withLyrics, words);
                    }
                } catch (error) {
                    setStatusMessage(`Error generating SRT: ${error.message}`);
                } finally {
                    setLoading(false);
                    setProgress(0);
                }
            } else {
                let enhancedWordsText = wordsDataInput.words
                    .filter(word => {
                        return word.word.indexOf('```') === -1 && word.word.length > 0;
                    })
                    .map(word => `${word.word}|${word.start}|${word.end}`)
                    .join(' ')
                    .trim();

                const updateSrt = () => {
                    // Join the lines back together after receiving the response
                    const enhancedWordsTextJoined = enhancedWordsText.split('\n').join(' ');
                    setSrt(enhancedWordsTextJoined);
                    setUntransformedSrt(enhancedWordsTextJoined);
                };

                let preparedLyrics = lyrics;
                setStreamingResponse(''); // Reset streaming response
                setLoading(true);

                if (withLyrics) {
                    const enhancedWordsTextStream = await enhanceLyrics(wordsDataInput, preparedLyrics);

                    // Use original words data to calculate total time estimate
                    const approxTotal = wordsDataInput.words.length;

                    enhancedWordsText = '';
                    for await (const chunk of enhancedWordsTextStream) {
                        const content = chunk.choices[0]?.delta?.content || '';
                        enhancedWordsText += content;
                        enhancedWordsText = enhancedWordsText.replace(/```.*?\n?/g, ''); // Remove code blocks
                        updateSrt();

                        const progressEstimate = Math.round(
                            (enhancedWordsText.split(/\n| /g).length / approxTotal) * 100
                        );
                        // 123/456 example
                        const progressLabel = `${enhancedWordsText.split(/\n| /g).length}/${approxTotal}`;
                        setProgress(progressEstimate);
                        setStatusMessage(`Enhancing lyrics... ${progressLabel}`);

                        // Scroll to bottom
                        if (wordSplitterRef.current) {
                            // wordSplitterRef.current.scrollTop = wordSplitterRef.current.scrollHeight;
                            // scroll
                            smoothScrollToBottom(wordSplitterRef.current);
                        }
                        if (srtRef.current) {
                            srtRef.current.scrollTop = srtRef.current.scrollHeight;
                        }
                    }

                    setStatusMessage('Lyrics enhanced successfully.');
                    setLoading(false);
                } else {
                    updateSrt();
                }
                setLoading(false);

                //console.log('enhancedWordsTextFormatted', enhancedWordsTextFormatted);
                setProgress(100);
                setStatusMessage('SRT generated successfully.');
            }
        };

        useEffect(() => {
            if (loading) {
                return;
            }
            (async () => {
                applyTextTransformations(untransformedSrt);
            })();
        }, [textTransformations, untransformedSrt, loading]);

        useEffect(() => {
            window.addEventListener('use-audio', e => {
                setAudioUrl(e.detail);
            });
        }, []);

        const audioPlayer = useMemo(() => {
            return (
                audioFile && (
                    <>
                        {/*<p>
                        <strong>
                            {audioFile.name} | {Math.round(audioFile.size / 1024 / 1024)} MB
                        </strong>
                    </p>
                    {audioFileHash}*/}
                        <AudioPlayer
                            key={audioFile.name}
                            ref={audioPlayerRef}
                            controls
                            src={URL.createObjectURL(audioFile)}
                            style={{
                                width: '100%'
                            }}
                            onTimeUpdate={e => {
                                window.dispatchEvent(
                                    new CustomEvent('audio-time-update', {
                                        detail: e.target.currentTime
                                    })
                                );
                            }}
                        />
                    </>
                )
            );
        }, [audioFile]);

        useEffect(() => {
            // Set mp3Cache[hash] to audioFile
            if (audioFile && audioFileHash) {
                mp3Cache[audioFileHash] = audioFile;
            }
        }, [audioFile, audioFileHash]);

        const loadAudio = async () => {
            if (progress > 0) {
                return;
            }

            setProgress(1);

            if (!audioUrl) {
                setStatusMessage('No audio url entered.');
                return;
            }

            setLoading(true);
            setStatusMessage('Downloading audio...');
            try {
                let audioUrlFixed = audioUrl;
                if (audioUrlFixed.indexOf('dropbox.com') !== -1) {
                    audioUrlFixed = audioUrlFixed.replace('www.dropbox.com', 'dl.dropbox.com');
                    audioUrlFixed = audioUrlFixed.replace('?dl=0', '?raw=1');
                }

                const response = await axios.get(audioUrlFixed, {
                    responseType: 'arraybuffer',
                    onDownloadProgress: progressEvent => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted * 0.2);
                    }
                });

                const hash = await crypto.subtle.digest('SHA-256', new Uint8Array(response.data));
                const hashArray = Array.from(new Uint8Array(hash));

                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                const name = hashHex + '.mp3';

                if (mp3Cache[name]) {
                    setAudioFile(await mp3Cache[name]);
                    setAudioFileName(name);
                    setStatusMessage(null);
                    setProgress(0);
                    return;
                }

                if (folderHandle) {
                    const folderHandleSub = await folderHandle.getDirectoryHandle('mp3', {
                        create: true
                    });

                    const files = await folderHandleSub.values();
                    for await (const file of files) {
                        if (file.name === name.replace(/\.(mp3|wav|ogg)$/, '') + '.mp3') {
                            const data = await file.getFile();
                            const audioFileMp3 = new File([data], file.name, {
                                type: 'audio/mp3'
                            });

                            mp3Cache[name] = audioFileMp3;
                            setAudioFile(audioFileMp3);
                            setAudioFileName(file.name);
                            setStatusMessage(null);
                            setProgress(0);

                            return;
                        }
                    }
                }

                const url = new URL(audioUrlFixed);
                let filename = url.pathname.split('/').pop();
                filename = filename.split('?')[0];

                const audioFile = new File([response.data], filename, {
                    type: response.headers['content-type']
                });

                setStatusMessage('Audio downloaded! Converting to mp3... ' + audioLength + 's');

                const total = audioLength;

                convertToMp3(audioFile, audioLength, folderHandle, name, async message => {
                    if (!message.includes('size=')) {
                        return;
                    }

                    let current = message.match(/time=(.*?) bitrate/)?.[1];
                    current = moment(current, 'HH:mm:ss.SS').diff(moment().startOf('day'), 'seconds') / 10;
                    const progress = (parseFloat(current) / parseFloat(total)) * 100;
                    console.log('progress', current, total, progress);
                    if (progress) {
                        setProgress(20 + parseFloat(progress) * 0.8);
                    }

                    setLogMessages(prev => [message]);
                }).then(audioFile => {
                    if (!audioFile) {
                        return;
                    }
                    setAudioFile(audioFile);
                    setStatusMessage(null);
                    setAudioFileName(audioFile.name);
                    setProgress(0);
                    setLogMessages([]);
                });
            } catch (error) {
                setStatusMessage(`Error: ${error.response?.data?.error || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            if (audioPlayerRef.current?.audio) {
                audioPlayerRef.current?.audio?.current.pause();
            }

            if (audioSrc) {
                setAudioUrl(audioSrc);
                setAudioFile(null);
                loadAudio();
            }
        }, [audioSrc]);

        const progressBar = (
            <>
                <Progress value={progress} mt={4} colorScheme="green" />
                <ProgressLabel>{statusMessage}</ProgressLabel>
            </>
        );

        return (
            <Modal size="3xl" isOpen={open} onClose={closeModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Generate Srt</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <AccessTokenInput value={openAiToken} onChange={e => setOpenAiToken(e.target.value)} />

                        <a
                            href="https://platform.openai.com/api-keys"
                            target="_blank"
                            style={{
                                marginTop: '10px',
                                display: 'block',
                                color: 'hsl(219 19% 79% / 1)'
                            }}
                        >
                            Get OpenAI API Key from the OpenAI Platform >>
                        </a>

                        {openAiToken && (
                            <>
                                {!audioSrc && !audioFile && (
                                    <>
                                        <MultiTypeInput
                                            fileName={audioFileName}
                                            url={audioUrl}
                                            onFile={e => {
                                                setAudioFile(e.target.files[0]);
                                                setAudioUrl('');
                                            }}
                                            onUrl={e => {
                                                setAudioUrl(e.target.value);
                                                setAudioFile(null);
                                            }}
                                        >
                                            <legend>
                                                Enter url instead for audio file (dropbox, google drive, etc.):
                                            </legend>
                                        </MultiTypeInput>
                                        <hr />

                                        <Button onClick={loadAudio}>Load Audio</Button>
                                        <hr />
                                    </>
                                )}
                            </>
                        )}

                        <PromptEditor
                            promptTemplate={promptTemplate}
                            setPromptTemplate={setPromptTemplate}
                            wordsData={wordsData}
                            lyrics={lyrics}
                        />

                        <ButtonGroup mt={4} display="flex" justifyContent="flex-start" flexWrap="wrap" mx={-2}>
                            <Button
                                onClick={() => {
                                    // Show confirmation warning
                                    if (srtData.length > 0) {
                                        if (
                                            !window.confirm(
                                                'Are you sure you want to clear the SRT data and use the original transcription?'
                                            )
                                        ) {
                                            return;
                                        }
                                    }
                                    setShowLyrics(false);
                                    setHideLyrics(true);
                                    handleGenerateSrt(false);
                                }}
                                mx={2}
                                colorScheme="purple"
                                style={
                                    showLyrics
                                        ? {
                                              // Not selected, so grayscale and fade opacity some
                                              filter: 'grayscale(100%)',
                                              opacity: 0.5
                                          }
                                        : {}
                                }
                            >
                                Generate SRT without lyrics
                            </Button>
                            <Button
                                onClick={() => {
                                    // Show confirmation warning
                                    if (srtData.length > 0) {
                                        if (
                                            !window.confirm(
                                                'Are you sure you want to clear the SRT data and generate a new SRT enhanced with lyrics?'
                                            )
                                        ) {
                                            return;
                                        }
                                    }
                                    setShowLyrics(true);
                                    setHideLyrics(false);
                                    // Clear everything
                                    setSrt('');
                                    setEnhancedSrt('');
                                    setSrtData('');
                                }}
                                mx={2}
                                colorScheme="purple"
                                style={
                                    !showLyrics
                                        ? {
                                              // Not selected, so grayscale and fade opacity some
                                              filter: 'grayscale(100%)',
                                              opacity: 0.5
                                          }
                                        : {}
                                }
                            >
                                Generate SRT with lyrics
                            </Button>
                            <ModelSelector model={model} setModel={setModel} openAiToken={openAiToken} />
                        </ButtonGroup>

                        {srt && srt.length > 0 && (
                            // Regenerate SRT with lyrics (from whisper)
                            <ButtonGroup>
                                <Button
                                    onClick={() => {
                                        setSrt('');
                                        setUntransformedSrt('');
                                        setEnhancedSrt('');
                                        setSrtData('');
                                        setWordsData('');

                                        // Close
                                        setTimeout(() => {
                                            closeModal();
                                        }, 100);
                                    }}
                                    mt={4}
                                    colorScheme="gray"
                                >
                                    Clear SRT data
                                </Button>
                            </ButtonGroup>
                        )}

                        {showLyrics && (
                            <CustomBox my={4}>
                                <AnimatePresence mode="wait">
                                    {showLyrics && !hideLyrics ? (
                                        <>
                                            <TextareaDebounced
                                                key="lyrics"
                                                value={lyrics}
                                                onInput={e => {
                                                    setLyrics(e.target.value);
                                                }}
                                                placeholder="Enter lyrics here..."
                                                rows={10}
                                                style={{
                                                    background: 'white'
                                                }}
                                            />
                                            <Button
                                                key="hideLyrics"
                                                as={motion.button}
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}}
                                                onClick={() => {
                                                    setHideLyrics(true);
                                                }}
                                                mt={2}
                                                colorScheme="gray"
                                            >
                                                Hide Lyrics
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            key="editLyrics"
                                            as={motion.button}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            onClick={() => {
                                                setHideLyrics(false);
                                            }}
                                            colorScheme="gray"
                                            mt={2}
                                        >
                                            Edit Lyrics
                                        </Button>
                                    )}
                                </AnimatePresence>
                                <Button
                                    onClick={() => {
                                        handleGenerateSrt(true);
                                        setHideLyrics(true);
                                    }}
                                    mt={2}
                                    colorScheme="green"
                                >
                                    Generate with Lyrics
                                </Button>
                            </CustomBox>
                        )}

                        {/* Special text modifications */}
                        {/*Ä<Select
                            mt={4}
                            value={textTransformations}
                            onChange={transformations => {
                                setTextTransformations(transformations);
                            }}
                            options={TEXT_TRANSFORMATIONS.map(transformation => {
                                transformation = JSON.parse(JSON.stringify(transformation));
                                if (textTransformations.indexOf(transformation[0]) > -1) {
                                    transformation[1] = '>> ' + transformation[1];
                                }
                                return transformation;
                            })}
                        />*/}

                        {/*  List of text transformations with button to apply */}
                        <ChipList
                            mt={4}
                            tags={textTransformations}
                            setTags={setTextTransformations}
                            inactiveTags={TEXT_TRANSFORMATIONS.map(t => t[0]).filter(
                                t => !textTransformations.includes(t)
                            )}
                        />

                        <Tag
                            onClick={async () => {
                                if (
                                    window.confirm(
                                        'Are you sure you want to apply the text transformations? This will undo any manual changes.'
                                    )
                                ) {
                                    applyTextTransformations(untransformedSrt);
                                }
                            }}
                            size="lg"
                            borderRadius="full"
                            variant="solid"
                            m={1}
                            className="handle"
                            style={{
                                cursor: 'pointer'
                            }}
                            colorScheme="pink"
                            transition="all 0.2s"
                            opacity={0.6}
                            _hover={{
                                opacity: 1
                            }}
                        >
                            <TagLabel>Apply Text Transformations ></TagLabel>
                        </Tag>

                        {progressBar}

                        {showSrt && srt && srt.length > 0 && (
                            // Show the original words and timings
                            <Textarea
                                value={srt}
                                onChange={e => setSrt(e.target.value)}
                                placeholder="SRT data..."
                                rows={10}
                                mt={4}
                            />
                        )}

                        {(audioFile || (audioUrl.length > 0 && audioUrl.match(/\.(mp3|wav|ogg)$/))) && audioPlayer}

                        {srt && srt.length > 0 && (
                            <WordSplitter
                                ref={wordSplitterRef}
                                disableHistory={loading}
                                words={srt}
                                onChange={newSrt => {
                                    console.log('newSrt', newSrt);
                                    setSrt(newSrt);
                                }}
                                onClear={() => {
                                    setSrt('');
                                    setEnhancedSrt('');
                                    setSrtData('');
                                }}
                                audioRef={audioPlayerRef.current?.audio}
                            />
                        )}

                        {showSrt && srtData && (
                            <Textarea
                                ref={srtRef}
                                value={srtData}
                                onChange={e => setSrtData(e.target.value)}
                                placeholder="SRT data..."
                                rows={10}
                                mt={4}
                            />
                        )}

                        {srtData && (
                            <>
                                <Button
                                    onClick={() => {
                                        window.dispatchEvent(
                                            new CustomEvent('use-srt', {
                                                detail: {
                                                    srt: srtData,
                                                    audioFileName: audioFileName,
                                                    // Add other data for future editing
                                                    data: {
                                                        lyrics,
                                                        hideLyrics,
                                                        srt,
                                                        srtData,
                                                        wordsData,
                                                        enhancedSrt
                                                    }
                                                }
                                            })
                                        );
                                        closeModal && closeModal();
                                    }}
                                    colorScheme="green"
                                    mt={4}
                                >
                                    Add SRT to project
                                </Button>
                                <Button
                                    onClick={() => {
                                        triggerDownload(srtData, 'srt', 'srt');
                                    }}
                                    colorScheme="gray"
                                    mt={4}
                                >
                                    Download SRT
                                </Button>
                                <Button
                                    onClick={() => {
                                        setShowSrt(!showSrt);
                                    }}
                                    colorScheme="gray"
                                    mt={4}
                                >
                                    {showSrt ? 'Hide SRT' : 'Show SRT'}
                                </Button>
                            </>
                        )}
                    </ModalBody>

                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        );
    }
);

// Error Boundary Component
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    render() {
        if (this.state.hasError) {
            return <h1>Error: {this.state.error.message}</h1>;
        }
        return this.props.children;
    }
}

const SrtGeneratorWordsBatchWIthErrorBoundary = props => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <SrtGeneratorWordsBatch {...props} />
            </Suspense>
        </ErrorBoundary>
    );
};

const ModelSelector = ({openAiToken, model, setModel, ...props}) => {
    const [models, setModels] = useLocalStorage('models', []);
    const [loading, setLoading] = useState(false);
    const [lastTime, setLastTime] = useLocalStorage('lastTime', 0);

    useEffect(() => {
        if (!openAiToken) {
            return;
        }

        // If it has been at least 4 hours since last time, refresh models
        if (Date.now() - lastTime > 1000 * 60 * 60 * 4) {
            setLoading(true);
            axios
                .get('https://api.openai.com/v1/models', {
                    headers: {
                        Authorization: `Bearer ${openAiToken}`
                    }
                })
                .then(response => {
                    setModels(response.data.data); //.filter(model => model.id.startsWith('gpt-4')));
                    setLastTime(Date.now());
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [openAiToken]);

    return (
        models.length > 0 && (
            <span style={{marginLeft: 'auto'}}>
                <ChakraSelect
                    bg="#d6bef8"
                    color="black"
                    _hover={{
                        bg: '#bfa0f6'
                    }}
                    _css={{
                        pointerEvents: loading ? 'none' : 'auto'
                    }}
                    // Lower width to fit in modal
                    w="150px"
                    value={model}
                    onChange={e => {
                        setModel(e.target.value);
                    }}
                    {...props}
                >
                    {models.map(model => (
                        <option key={model.id} value={model.id}>
                            {model.id}
                        </option>
                    ))}
                </ChakraSelect>
            </span>
        )
    );
};

export {Row, Column} from './Grid.js';
export default SrtGeneratorWordsBatch;

import React, {useState, useEffect, useCallback, forwardRef, useRef} from 'react';
import {Box, Button, Flex, VStack, HStack, Wrap, WrapItem} from '@chakra-ui/react';
import {FaTrash, FaCut, FaArrowLeft, FaArrowRight, FaUndo, FaRedo} from 'react-icons/fa';
import {motion} from 'framer-motion';

import {useLogger} from '../DebugLogger.js';

const WrapItemMotion = React.memo(motion(WrapItem));

/**
 * Custom hook to manage state synchronized with localStorage.
 */
const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => {
        try {
            const storedValue = localStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : initialValue;
        } catch (error) {
            console.error('Error reading from localStorage:', error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error writing to localStorage:', error);
        }
    }, [key, value]);

    return [value, setValue];
};

/**
 * Component to display timing labels for each word.
 */
const TimingLabel = ({start: startProp, end: endProp, onUpdate, ...props}) => {
    const [start, setStart] = useState(parseFloat(startProp));
    const [end, setEnd] = useState(parseFloat(endProp));

    const startPointerLock = useCallback(e => {
        e.target.requestPointerLock();

        const updateStartTime = e => {
            const delta = e.movementX / 100;
            setStart(prev => Math.max(0, prev + delta));
        };

        document.addEventListener('pointerlockchange', () => {
            if (document.pointerLockElement === e.target) {
                e.target.addEventListener('mousemove', updateStartTime);
            } else {
                e.target.removeEventListener('mousemove', updateStartTime);
            }
        });

        document.addEventListener('pointerlockerror', () => {
            console.log('Pointer Lock Error');
        });

        document.addEventListener('mouseup', () => {
            document.exitPointerLock();
            e.target.removeEventListener('mousemove', updateStartTime);
        });
    }, []);

    const from = (
        <motion.span
            // Dragging on this span will update the start time by increments of 0.1 or decrease by 0.1
            // With very low drag velocity, the start time will be updated by 0.1
            // With high drag velocity, the start time will be updated by 1.0
            // Use the pointer lock API to reset  cursor  position to the center of the span until the drag ends
            onMouseDown={e => {
                startPointerLock(e);
            }}
            style={{
                cursor: 'ew-resize',
                userSelect: 'none',
                display: 'inline-block',
                minWidth: '3em',
                textAlign: 'right'
            }}
        >
            {parseFloat(start).toFixed(1)}
        </motion.span>
    );
    const to = (
        <motion.span
        //
        >
            {parseFloat(end).toFixed(1)}
        </motion.span>
    );
    return (
        <Box
            as="span"
            fontSize="xs"
            fontWeight="bold"
            color="gray.500"
            whiteSpace="nowrap"
            userSelect="none"
            mr={2}
            position="absolute"
            bottom="80%"
            opacity="var(--timing-label-opacity, 0)"
            transition="opacity 0.2s"
        >
            {from} - {to}
        </Box>
    );
};

/**
 * Main WordSplitter component.
 */
let WordSplitter = forwardRef(({audioRef, words, onChange, onClear, disableHistory}, ref) => {
    const [wordItems, setWordItems] = useState([]);
    const [realSplits, setSplits] = useLocalStorage('splits2', []);
    const [tempSplits, setTempSplits] = useState([]);
    const [historyState, setHistoryState] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [editingIndex, setEditingIndex] = useState(null);
    const handleSaveRef = useRef();
    const wordItemsRef = useRef();
    const editableRefs = useRef([]);
    const isUndoRedoActionRef = useRef(false);

    const [splits, setRealSplits] = useState(realSplits);

    // **New State for Tracking Current Word Index**
    const [currentWordIndex, setCurrentWordIndex] = useState(null);

    /**
     * Logger for debugging purposes.
     */
    useLogger(
        'WordSplitter',
        () => {
            return JSON.stringify(
                wordItems.slice(0, 5).map((item, index) => {
                    const {word, start, end} = item;
                    return {word, start, end, split: splits.includes(index)};
                }),
                null,
                2
            );
        },
        [wordItems]
    );

    /**
     * Initialize history with words when component mounts or words change.
     */
    useEffect(() => {
        if (words && historyState.length === 0) {
            setHistoryState([words]);
            setHistoryIndex(0);
        }
    }, [words, historyState]);

    /**
     * Parse the words string into word items and manage splits.
     */
    useEffect(() => {
        if (words) {
            const lines = words.split('\n');
            let items = [];
            let newSplits = [];
            let wordIndex = 0;

            lines.forEach((line, lineIndex) => {
                const lineWords = line.split(/\s+/).filter(Boolean);
                lineWords.forEach((word, index) => {
                    const [text, start, end] = word.split('|');
                    items.push({
                        word: text,
                        start: parseFloat(start),
                        end: parseFloat(end)
                    });
                    if (index === lineWords.length - 1 && lineIndex !== lines.length - 1) {
                        newSplits.push(wordIndex);
                    }
                    wordIndex++;
                });
            });

            setWordItems(items);
            setSplits(newSplits);
        }

        if (!isUndoRedoActionRef.current && !disableHistory) {
            // Add to history
            addToHistory(words);
        } else {
            setTimeout(() => {
                isUndoRedoActionRef.current = false;
            }, 10);
        }
    }, [words, disableHistory]);

    /**
     * Sync temporary splits with real splits.
     */
    useEffect(() => {
        if (tempSplits.length > 0) {
            setRealSplits(tempSplits);
        } else {
            setRealSplits(realSplits);
        }
    }, [tempSplits, realSplits]);

    /**
     * Handler to change a specific word.
     */
    const handleWordChange = useCallback((index, newWord) => {
        setWordItems(prev => {
            const updated = [...prev];
            updated[index] = {...updated[index], word: newWord};
            return updated;
        });
    }, []);

    /**
     * Handler to split a word at the cursor position.
     */
    const handleSplit = useCallback(() => {
        if (editingIndex === null) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const splitIndex = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const [firstPart, secondPart] = [currentItem.word.slice(0, splitIndex), currentItem.word.slice(splitIndex)];

            const totalLength = currentItem.word.length;
            const firstLength = firstPart.length;
            const secondLength = secondPart.length;

            const startTime = currentItem.start;
            const endTime = currentItem.end;
            const totalDuration = endTime - startTime;

            const firstDuration = (firstLength / totalLength) * totalDuration;
            const secondDuration = (secondLength / totalLength) * totalDuration;

            const firstEndTime = parseFloat((startTime + firstDuration).toFixed(3));
            const secondStartTime = parseFloat((endTime - secondDuration).toFixed(3));

            updated[editingIndex] = {
                ...currentItem,
                word: firstPart,
                end: firstEndTime
            };
            updated.splice(editingIndex + 1, 0, {
                word: secondPart,
                start: secondStartTime,
                end: currentItem.end
            });

            setSplits(prevSplits => {
                const newSplits = prevSplits.map(split => (split > editingIndex ? split + 1 : split));
                setRealSplits(newSplits);
                return newSplits;
            });

            return updated;
        });

        setTimeout(() => {
            if (currentRef) {
                currentRef.focus();
                const range = document.createRange();
                range.setStart(currentRef.firstChild, splitIndex);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }, 0);
    }, [editingIndex]);

    /**
     * Handler to merge the current word with the previous one.
     */
    const handleMergePrevious = useCallback(() => {
        if (editingIndex === null || editingIndex === 0) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const cursorPosition = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const prevItem = updated[editingIndex - 1];

            const prevLength = prevItem.word.length;

            const mergedWord = prevItem.word + currentItem.word;
            const mergedStart = prevItem.start;
            const mergedEnd = currentItem.end;

            updated[editingIndex - 1] = {
                word: mergedWord,
                start: mergedStart,
                end: mergedEnd
            };
            updated.splice(editingIndex, 1);

            setSplits(prevSplits => {
                const newSplits = prevSplits
                    .filter(split => split !== editingIndex - 1 && split !== editingIndex)
                    .map(split => (split > editingIndex ? split - 1 : split));
                setRealSplits(newSplits);
                return newSplits;
            });

            setEditingIndex(editingIndex - 1);

            setTimeout(() => {
                const newRef = editableRefs.current[editingIndex - 1];
                if (newRef) {
                    newRef.focus();
                    const range = document.createRange();
                    range.setStart(newRef.firstChild, cursorPosition + prevLength);
                    range.collapse(true);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }, 0);

            return updated;
        });
    }, [editingIndex]);

    /**
     * Handler to merge the current word with the next one.
     */
    const handleMergeNext = useCallback(() => {
        if (editingIndex === null || editingIndex === wordItems.length - 1) return;

        const currentRef = editableRefs.current[editingIndex];
        const selection = window.getSelection();
        const cursorPosition = selection.anchorOffset;

        setWordItems(prev => {
            const updated = [...prev];
            const currentItem = updated[editingIndex];
            const nextItem = updated[editingIndex + 1];

            const mergedWord = currentItem.word + nextItem.word;
            const mergedStart = currentItem.start;
            const mergedEnd = nextItem.end;

            updated[editingIndex] = {
                word: mergedWord,
                start: mergedStart,
                end: mergedEnd
            };
            updated.splice(editingIndex + 1, 1);

            setSplits(prevSplits =>
                prevSplits
                    .filter(split => split !== editingIndex && split !== editingIndex + 1)
                    .map(split => (split > editingIndex + 1 ? split - 1 : split))
            );

            return updated;
        });

        setTimeout(() => {
            if (currentRef) {
                currentRef.focus();
                const range = document.createRange();
                range.setStart(currentRef.firstChild, cursorPosition);
                range.collapse(true);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        }, 0);
    }, [editingIndex, wordItems.length]);

    /**
     * Handler to toggle line splits.
     */
    const handleLineSplit = useCallback(index => {
        setSplits(prevSplits => {
            const newSplits = prevSplits.includes(index)
                ? prevSplits.filter(split => split !== index)
                : [...prevSplits, index].sort((a, b) => a - b);
            return newSplits;
        });
    }, []);

    /**
     * Handler for Undo action.
     */
    const handleUndo = useCallback(() => {
        if (historyIndex > 0) {
            isUndoRedoActionRef.current = true;
            const newIndex = historyIndex - 1;
            setHistoryIndex(newIndex);
            const previousState = historyState[newIndex];
            onChange(previousState);
        }
    }, [historyIndex, historyState, onChange]);

    /**
     * Handler for Redo action.
     */
    const handleRedo = useCallback(() => {
        if (historyIndex < historyState.length - 1) {
            isUndoRedoActionRef.current = true;
            const newIndex = historyIndex + 1;
            setHistoryIndex(newIndex);
            const nextState = historyState[newIndex];
            onChange(nextState);
        }
    }, [historyIndex, historyState, onChange]);

    /**
     * Adds a new state to the history for undo/redo functionality.
     */
    const addToHistory = useCallback(
        newState => {
            if (!isUndoRedoActionRef.current) {
                setHistoryState(prev => {
                    const newHistory = prev.slice(0, historyIndex + 1);
                    newHistory.push(newState);
                    setHistoryIndex(newHistory.length - 1);
                    return newHistory;
                });

                // Also reset index
                setHistoryIndex(prevIndex => historyState.length);
            }
        },
        [historyIndex, historyState.length]
    );

    /**
     * Handler to add a temporary split.
     */
    const handleAddTempSplit = useCallback(
        index => {
            setTempSplits([...splits, index].sort((a, b) => a - b));
        },
        [splits]
    );

    /**
     * Handler to remove temporary splits.
     */
    const handleRemoveTempSplit = useCallback(() => {
        setTempSplits([]);
    }, []);

    /**
     * Handler to save the current state.
     */
    const handleSave = useCallback(() => {
        let result = wordItems
            .map((item, index) => {
                const {word, start, end} = item;
                const split = splits.includes(index) ? '\n' : ' ';
                return `${word}|${start}|${end}${split}`;
            })
            .join('')
            .trim();
        onChange(result);
    }, [wordItems, splits, onChange, addToHistory]);

    /**
     * Handler for input events on editable words.
     */
    const handleInput = useCallback(
        (index, event) => {
            const newWord = event.target.textContent.replace(/ /g, '');
            handleWordChange(index, newWord);
        },
        [handleWordChange]
    );

    /**
     * Reference to the latest handleSave function.
     */
    useEffect(() => {
        handleSaveRef.current = handleSave;
    }, [handleSave]);

    /**
     * Update wordItemsRef to hold the latest wordItems and splits.
     */
    useEffect(() => {
        wordItemsRef.current = {
            wordItems,
            splits: realSplits
        };
    }, [wordItems, realSplits]);

    /**
     * Auto-save mechanism running at intervals.
     */
    useEffect(() => {
        let lastWordItems = JSON.stringify(wordItemsRef.current);
        const saveInterval = setInterval(() => {
            const currentItemsStringed = JSON.stringify({
                wordItems: wordItemsRef.current.wordItems,
                splits: wordItemsRef.current.splits
            });
            if (lastWordItems !== currentItemsStringed) {
                handleSaveRef.current && handleSaveRef.current();
                lastWordItems = currentItemsStringed;
                console.log('Saved');
            }
        }, 800);

        return () => {
            clearInterval(saveInterval);
        };
    }, []);

    /**
     * **New Effect to Monitor currentTime and Highlight Current Word**
     */
    useEffect(() => {
        if (!audioRef || !audioRef.current) return;

        let animationFrameId;

        const updateCurrentWord = () => {
            const currentTime = audioRef.current.currentTime;
            // Find the word where start <= currentTime < end
            const currentIndex = wordItems.findIndex(word => currentTime >= word.start && currentTime < word.end);
            setCurrentWordIndex(currentIndex !== -1 ? currentIndex : null);
            animationFrameId = requestAnimationFrame(updateCurrentWord);
        };

        animationFrameId = requestAnimationFrame(updateCurrentWord);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [audioRef, wordItems]);

    /**
     * **New Effect to Scroll Highlighted Word into View**
     */
    useEffect(() => {
        if (currentWordIndex !== null && editableRefs.current[currentWordIndex]) {
            editableRefs.current[currentWordIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [currentWordIndex]);

    return (
        <VStack
            as={motion.div}
            ref={ref}
            spacing={4}
            align="stretch"
            borderWidth="1px"
            borderBottomLeftRadius="lg"
            borderBottomRightRadius="lg"
            p={4}
            maxHeight="300px"
            overflowY="auto"
        >
            {/* Control Buttons */}
            <Flex justifyContent="flex-end" position="sticky" top={0} zIndex={1}>
                <Button leftIcon={<FaUndo />} onClick={handleUndo} size="sm" mr={2} isDisabled={historyIndex <= 0}>
                    Undo ({historyIndex})
                </Button>
                <Button
                    leftIcon={<FaRedo />}
                    onClick={handleRedo}
                    size="sm"
                    mr={2}
                    isDisabled={historyIndex >= historyState.length - 1}
                >
                    Redo ({historyState.length - historyIndex - 1})
                </Button>
                <Button
                    leftIcon={<FaTrash />}
                    onClick={() => {
                        setWordItems([]);
                        setSplits([]);
                        onClear();
                        addToHistory('');
                    }}
                    size="sm"
                    mr={2}
                >
                    Clear
                </Button>
            </Flex>

            {/* Words Display */}
            <Wrap spacing={2}>
                {wordItems.map((item, index) => [
                    <WrapItemMotion
                        key={index}
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.2}}
                        zIndex={splits.includes(index) ? 1 : 0}
                    >
                        <VStack spacing={0} align="stretch">
                            <HStack
                                position="relative"
                                _hover={{
                                    '--timing-label-opacity': 1
                                }}
                            >
                                {/* Timing Label */}
                                <TimingLabel
                                    start={item.start}
                                    end={item.end}
                                    onUpdate={(newItem, index) => {
                                        const newItems = [...wordItems];
                                        newItems[index] = newItem;
                                        setWordItems(newItems);
                                    }}
                                />

                                {/* Editable Word Span */}
                                <span
                                    ref={el => (editableRefs.current[index] = el)}
                                    contentEditable
                                    suppressContentEditableWarning
                                    onKeyDown={e => {
                                        if (e.key === 'Tab') {
                                            e.preventDefault();
                                            let inc = 1;

                                            if (e.shiftKey) {
                                                inc = -1;
                                            }

                                            const nextIndex = index + inc;
                                            if (nextIndex < wordItems.length && nextIndex >= 0) {
                                                editableRefs.current[nextIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(
                                                    editableRefs.current[nextIndex].firstChild,
                                                    editableRefs.current[nextIndex].textContent.length
                                                );
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        }

                                        // Custom Key Handling
                                        const gotoNextWord = () => {
                                            const nextIndex = index + 1;
                                            if (nextIndex < wordItems.length) {
                                                editableRefs.current[nextIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(editableRefs.current[nextIndex].firstChild, 0);
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        };

                                        const gotoPreviousWord = () => {
                                            const prevIndex = index - 1;
                                            if (prevIndex >= 0) {
                                                editableRefs.current[prevIndex].focus();
                                                const range = document.createRange();
                                                range.setStart(
                                                    editableRefs.current[prevIndex].firstChild,
                                                    editableRefs.current[prevIndex].textContent.length
                                                );
                                                range.collapse(true);
                                                window.getSelection().removeAllRanges();
                                                window.getSelection().addRange(range);
                                            }
                                        };

                                        // 1. Arrow Right at End: Move to Next Word
                                        if (
                                            e.key === 'ArrowRight' &&
                                            window.getSelection().anchorOffset === item.word.length
                                        ) {
                                            e.preventDefault();
                                            gotoNextWord();
                                        }

                                        // 2. Arrow Left at Start: Move to Previous Word
                                        if (e.key === 'ArrowLeft' && window.getSelection().anchorOffset === 0) {
                                            e.preventDefault();
                                            gotoPreviousWord();
                                        }

                                        // 3. Backspace at Start: Merge with Previous Word
                                        if (e.key === 'Backspace' && window.getSelection().anchorOffset === 0) {
                                            e.preventDefault();
                                            handleMergePrevious();
                                        }

                                        // 4. Space within Word: Split Word at Cursor
                                        if (e.key === ' ' && window.getSelection().anchorOffset > 0) {
                                            e.preventDefault();

                                            handleSplit();

                                            setTimeout(() => {
                                                gotoNextWord();
                                            }, 0);
                                        }
                                    }}
                                    onBlur={e => {
                                        handleInput(index, e);
                                        if (window.blurringEditingIndex) {
                                            clearTimeout(window.blurringEditingIndex);
                                        }
                                        window.blurringEditingIndex = setTimeout(() => {
                                            setEditingIndex(null);
                                        }, 100);
                                    }}
                                    onFocus={() => {
                                        if (window.blurringEditingIndex) {
                                            clearTimeout(window.blurringEditingIndex);
                                            window.blurringEditingIndex = null;
                                        }
                                        setEditingIndex(index);
                                    }}
                                    style={{
                                        display: 'inline-block',
                                        minWidth: '1em',
                                        padding: '2px 4px',
                                        border: '1px solid transparent',
                                        borderRadius: '2px',
                                        outline: 'none',
                                        backgroundColor:
                                            index === currentWordIndex
                                                ? 'rgba(246, 224, 94, 0.4)' // Semi-transparent yellow
                                                : 'transparent',
                                        transition: 'background-color 0.3s',
                                        cursor: 'text'
                                    }}
                                >
                                    {item.word}
                                </span>

                                {/* Split Button */}
                                <Button
                                    onClick={() => {
                                        handleLineSplit(index);
                                    }}
                                    transition="background-color 0.2s"
                                    size="sm"
                                    variant={splits.includes(index) ? 'solid' : 'ghost'}
                                    colorScheme={splits.includes(index) ? 'green' : 'gray'}
                                    h="24px"
                                    p={0}
                                    m={0}
                                    mx="0px"
                                    minW="24px"
                                    _hover={{
                                        bg: (() => {
                                            let color = 'gray';
                                            if (realSplits.includes(index)) {
                                                color = 'red';
                                            } else if (tempSplits.includes(index)) {
                                                color = 'purple';
                                            }
                                            return color;
                                        })(),
                                        color: 'white !important'
                                    }}
                                >
                                    ↵
                                </Button>
                            </HStack>

                            {/* Edit Actions (Currently Disabled) */}
                            {0 && editingIndex === index && (
                                <HStack key="edit-actions">
                                    <Button size="xs" onClick={handleMergePrevious} isDisabled={index === 0}>
                                        <FaArrowLeft />
                                    </Button>
                                    <Button size="xs" onClick={handleSplit}>
                                        <FaCut />
                                    </Button>
                                    <Button
                                        size="xs"
                                        onClick={handleMergeNext}
                                        isDisabled={index === wordItems.length - 1}
                                    >
                                        <FaArrowRight />
                                    </Button>
                                </HStack>
                            )}
                        </VStack>
                    </WrapItemMotion>,
                    splits.includes(index) && <WrapItem key={`split-${index}`} flexBasis="100%" height={0} />
                ])}
            </Wrap>
        </VStack>
    );
});

WordSplitter.displayName = 'WordSplitter';

WordSplitter = React.memo(WordSplitter);

export default WordSplitter;
